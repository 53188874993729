import React from 'react';
import { Helmet, MetaProps } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export interface SEOProps {
  title?: string;
  description?: string;
  lang?: string;
  meta?: MetaProps[];
  imageURL?: string;
}

const SEO = ({ title, description, lang = 'en', meta = [], imageURL }: SEOProps) => {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            image
          }
        }
      }
    `,
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = `${site.siteMetadata.siteUrl}${imageURL || site.siteMetadata.image}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={title ? `%s - ${site.siteMetadata.title}` : site.siteMetadata.title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: 'sms.semurl',
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'twitter:image',
          content: metaImage,
        },
        {
          property: 'og:image:url',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
